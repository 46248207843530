import { Flex, Box, Stack, Link, Code, Heading, useColorModeValue } from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import { useRouter } from 'next/router';
import useListMicroServiceRelationsFlow from '../../../logic/flows/useListMicroServiceRelations';
import Loader from './Loader';

const markdownOptions = {
  overrides: {
    a: {
      component: Link,
    },
    code: {
      component: Code,
      props: {
        padding: '10px',
        borderRadius: '2px',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    h1: {
      component: Heading,
      props: {
        level: 1,
        size: 'lg',
        mt: '4px',
        mb: '4px',
      },
    },
    h2: {
      component: Heading,
      props: {
        level: 2,
        size: 'lg',
        mt: '4px',
        mb: '4px',
      },
    },
    h3: {
      component: Heading,
      props: {
        level: 3,
        size: 'md',
        mt: '4px',
        mb: '4px',
      },
    },
    h4: {
      component: Heading,
      props: {
        level: 4,
        size: 'md',
        mt: '4px',
        mb: '4px',
      },
    },
    h5: {
      component: Heading,
      props: {
        level: 5,
        size: 'md',
        mt: '4px',
        mb: '4px',
      },
    },
  },
};

const ControlledDocumentation = () => {
  const router = useRouter();
  const slug = router?.query?.id;
  const { rows, status } = useListMicroServiceRelationsFlow({ slug });
  const heading = rows?.[0]?.frontend?.education?.spec?.tabs?.[0]?.name;
  const columns = rows?.[0]?.frontend?.education?.spec?.tabs?.[0]?.spec?.columns;
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  if (status === 'loading') {
      return <Loader />
  }
  if (columns?.length) {
    return (
      <Flex direction="column">
        <Flex
          align="center"
          justify="start"
          bgColor={bgColor}
          px={{ base: 5, md: 10 }}
          w="100%"
          h="50px"
        >
          <Heading size="sm">{heading}</Heading>
        </Flex>
        <Stack p={{ base: 5, md: 10 }} spacing={10} direction={{ base: 'column', md: 'row' }}>
          {
            columns?.length > 0 && columns.map((column, idx) => (
              <Box key={idx} width="100%">
                <Markdown
                  options={markdownOptions}
                  // eslint-disable-next-line react/no-children-prop
                  children={column}
                />
              </Box>
            ))
          }
        </Stack>
      </Flex>
    )
  }

  return null;
}

export default ControlledDocumentation;
