/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Text, Center, useColorModeValue } from '@chakra-ui/react';
import { useState } from 'react';
import { capitalizeString } from '../../helpers/utility';
import LanguageMenu from './LanguageMenu';
import RelationsMenu from './RelationsMenu';

interface IProps {
  spec: any[];
  languages: string[];
  onEvent: (text: string, language: string) => void;
}

const CodeBlockHeader = (props: IProps) => {
  const { spec, languages, onEvent } = props;

  const actions =
    spec?.[0]?.spec &&
    spec?.[0]?.spec.length > 0 &&
    spec?.[0]?.spec.map((s) => s.action);

  // define initial state
  const initState = {
    actions,
    currentAction: actions?.[0],
    currentLanguage: languages?.[0],
  };

  const [state, setState] = useState(initState);

  const handleLanguageChange = (language) => {
    // actions list will change
    // current action will change
    // current language will change
    const newSpec = spec.find((s) => s.language === language)?.spec;
    const newActions =
      newSpec && newSpec.length > 0 && newSpec.map((s) => s.action);
    const newCurrentAction = newActions?.[0];
    const text = newSpec?.[0]?.value;
    onEvent(text, language);
    setState({
      actions: newActions,
      currentAction: newCurrentAction,
      currentLanguage: language,
    });
  };

  const handleActionChange = (action) => {
    // actions remain same
    // current language remains same
    // currentAction changes
    const currentSpec = spec.find((s) => s.language === state.currentLanguage)
      ?.spec;
    const text = currentSpec.find((s) => s.action === action)?.value;
    onEvent(text, state.currentLanguage);
    setState({ ...state, currentAction: action });
  };



  return (
    <Flex
      w="100%"
      borderTopRadius="8px"
      bg="gray.800"
      justify="space-between"
      align="center"
      h="40px"
    >
      <Flex
        css={{
          '&::-webkit-scrollbar': { display: 'none' },
        }}
        w={{ base: '50%', md: '60%' }}
        overflowX="auto"
        overflowY="hidden"
        justify="start"
        align="center"
        h="100%"
        color="white"
      >
        {state.actions &&
          state.actions.length > 0 &&
          state.actions.map((action, index) => (
            <Flex
              key={index}
              cursor="pointer"
              borderTopLeftRadius={index === 0 ? '8px' : '0px'}
              onClick={() => handleActionChange(action)}
              bg={state.currentAction === action ? 'gray.600' : 'gray.800'}
              px={{ base: 1, md: 3 }}
              py={2}
              h="full"
              align="center"
              _hover={{bg: 'gray.700'}}
            >
              <Text
                isTruncated
                // color={action === state.currentAction ? 'orange' : 'white'}
                key={index}
                fontSize={{ base: 'sm', md: 'md' }}
              >
                {capitalizeString(action)}
              </Text>
            </Flex>
          ))}
      </Flex>
      <Flex
        w={{ base: '50%', md: '40%' }}
        align="center"
        pr={2}
        justify="flex-end"
      >
        <RelationsMenu />
        <Box ml={5}>
          <LanguageMenu
            onEvent={(language) => handleLanguageChange(language)}
            languages={languages}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default CodeBlockHeader;
