import { QueryClient } from 'react-query';
import models from './queryModels';
import useList from './useList';
import useGet from './useGet';
import useMutate from './useMutate';

const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });

const useCreate = useMutate;
const useDelete = useMutate;
const useUpdate = useMutate;


export {models, useList, useCreate, useDelete, useGet, useUpdate,  queryClient}