import { useToast } from "@chakra-ui/react";
import TYPES from "../../config/types";
import { models, queryClient, useDelete } from "../queries";
import useStore from "../zustand";
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../apiKeys';

interface Payload {
    id: string
}

interface IProps {
    triggerId?: string
}

const useRemoveMicroServiceInstanceFlow = (props: IProps) => {

    const { triggerId } = props;
    const queryKey = `${models.microserviceClusters.name  }-delete`;

    const {setSelect, closeModal, setWaiting, clearWaiting} = useStore((store) => ({
        setSelect: store.setSelect,
        closeModal: store['modal/close'],
        setWaiting: store.setWaiting,
        clearWaiting: store.clearWaiting
    }))


    const toast = useToast();
  const removeMicroService = (payload) => apiWithToken({
    url: apiKeys['delete.microservice'],
    method: 'POST',
    payload
  })
    const removeMicroServiceInstance = async (payload: Payload) => {
        try {
            setWaiting(TYPES.WAITING.PROCESSING.DELETE_MICROSERVICE, true);
            await removeMicroService(payload);
            queryClient.refetchQueries({
                queryKey: [models.microserviceClusters.name]
            })
            toast({
                title: 'Instance deleted.',
                description: 'Your microservice instance has been deleted.',
                status: 'success',
                duration: 10000,
                isClosable: true,
                position: 'top'
            })

        } catch (error) {
            toast({
                title: 'Some error occurred.',
                description: 'Please try again later.',
                status: 'error',
                duration: 10000,
                isClosable: true,
                position: 'top'
            })
        } finally {
            closeModal({type: TYPES.MODALS.DELETE_MICROSERVICE});
            clearWaiting(TYPES.WAITING.PROCESSING.DELETE_MICROSERVICE);
            setSelect(TYPES.SELECT.MICROSERVICE_RECORD, false);
        }
    };

    return removeMicroServiceInstance;
};

export default useRemoveMicroServiceInstanceFlow;
