import { useToast } from '@chakra-ui/react';
import { TYPES } from '../../index';
import useStore from '../zustand'
import { useDeleteFlow } from '../hooks/useDeleteFlow';

function useCreateFlowInteraction () {
  const toast = useToast()
  const { closeModal, setSelect } = useStore((store) => ({
    closeModal: store['modal/close'],
    setSelect: store.setSelect
  }))
  const { deleteFlow, error, isLoading } = useDeleteFlow()
  const flowDelete = async (id) => {
    try {
      await deleteFlow({id});
      toast({
        title: "Flow has been deleted.",
        status: "success",
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "Could not delete flow",
        status: "error",
        duration: 5000,
        position: 'top',
        isClosable: true,
      })
    } finally {
      closeModal({type: TYPES.MODALS.DELETE_RECIPE});
      setSelect(TYPES.SELECT.RECIPE_RECORD, false);
    }
  }

  return { flowDelete, error, isLoading };
}

export default useCreateFlowInteraction
