import { useToast } from '@chakra-ui/react';
import TYPES from '../../config/types';
import { models, queryClient, useCreate } from '../queries';
import useStore from '../zustand';
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../apiKeys';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Payload {
  [k: string]: any;
}

interface IProps {
  triggerIdCreate: string;
  triggerIdGetStatus: string
}

const useCreateConnectionFlow = (props: IProps) => {

  const { triggerIdCreate } = props;
  const { setWaiting, clearWaiting, setSelect, closeModal } = useStore((store) => ({
    setWaiting: store.setWaiting,
    clearWaiting: store.clearWaiting,
    setSelect: store.setSelect,
    closeModal: store['modal/close']
  }));

  const toast = useToast();
  const createConnection = (payload) => apiWithToken({
  url: apiKeys['create.microservice'],
  method: 'POST',
  payload
})
  const createConnectionFlow = async (payload: Payload) => {
    try {
      setWaiting(TYPES.WAITING.PROCESSING.CREATE_CONNECTION, true);
      setSelect(
        TYPES.SELECT.CONNECTION_CREATE_PROCESSING_MESSAGE,
        TYPES.MICROSERVICE_INSTANCE_CREATION_STATUS.CONNECTING
        );
        await createConnection(payload);
        queryClient.refetchQueries({
          queryKey: [models.microserviceClusters.name]
        });
        closeModal();
        toast({
          title: 'Connection initiated.',
          description: 'We have initiated your connection. It might take 3-5 minutes to spin up.',
          status: 'success',
          duration: 10000,
          isClosable: true,
          position: 'top'
        })
    } catch ({response}) {
      setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, {
        message: response?.data?.message || 'Some error occurred!'
      });
    } finally {
      clearWaiting(TYPES.WAITING.PROCESSING.CREATE_CONNECTION);
    }
  };

  return createConnectionFlow;
};

export default useCreateConnectionFlow;
