import { Box, Skeleton, Stack } from '@chakra-ui/react';
import { IForm } from './interface';

interface IProps {
  dynamicFormData: IForm[];
}

const FormLoader = (props: IProps) => {
  const { dynamicFormData } = props;
  return (
    <Stack spacing={10} w="100%">
      {dynamicFormData.map((data, index) => (
        <Stack key={index} spacing={5}>
          <Skeleton w="40%" height="15px" />
          <Skeleton w="100%" height="25px" />
        </Stack>
      ))}
    </Stack>
  );
};

export default FormLoader;
