/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/number-input';
import { FC } from 'react';
import { IRules } from '../shared/interface';

interface IProps {
  label?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: any;
  max?: number;
  min?: number;
  step?: number;
  rules?: IRules
}

const FormNumberInput: FC<IProps> = ({
  label,
  name,
  placeholder,
  register,
  errors,
  max,
  min,
  step,
  rules
}) => (
    <FormControl isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>{label}</FormLabel>
      <NumberInput step={step} min={min} max={max}>
        <NumberInputField placeholder={placeholder} {...register(name, rules)} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );

export default FormNumberInput;
