/* eslint-disable import/prefer-default-export */
const domains = {
  development: 'https://development-api.buildable.io',
};

const env = process?.env?.NODE_ENV ?? 'development';

function getInternalUrl(environment) {
  const domain = domains[environment] ?? domains.development;
  return `${domain}/internal`;
}

export const apiKeys = {
  'list.flows': `${getInternalUrl(env)}/v1/flows/list`,
  'search.flows': `${getInternalUrl(env)}/v1/flows/search`,
  'create.empty.flow': `${getInternalUrl(env)}/v1/flows/create.empty.flow`,
  'fork': `${getInternalUrl(env)}/v1/flows/fork`,
  'delete': `${getInternalUrl(env)}/v1/flows/delete`,
  'create.microservice': `${getInternalUrl(env)}/v1/microservices/create`,
  'sync.microservice': `${getInternalUrl(env)}/v1/microservices/sync-and-generate-types`,
  'delete.microservice': `${getInternalUrl(env)}/v1/microservices/delete`,
  'update': `${getInternalUrl(env)}/v1/flows/update`
}
