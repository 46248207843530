import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { FC } from 'react';
import TYPES from '../../../config/types';
import useStore from '../../../logic/zustand';
import ConnectForm from './ConnectForm';

const ConnectToMicroServiceModalStepTwo: FC = () => {
  const { microservicePackage, openModal, closeModal, isOpenModal } = useStore(
    (store) => ({
      microservicePackage:
        store.select[TYPES.SELECT.MICROSERVICE_PACKAGE_OPTION],
      openModal: store['modal/open'],
      closeModal: store['modal/close'],
      isOpenModal: store.modal.isOpen
    })
  );

  const bg = useColorModeValue('white', 'black');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const { docs, formData, recipes } = microservicePackage;

  const handleOnClick = () => {
    openModal({ type: TYPES.MODALS.CONNECT_TO_MICRO_SERVICE_STEP_ONE });
  };

  const size = useBreakpointValue({md: '4xl'});
  const isCentered = useBreakpointValue({md: true});

  return (
    <Modal closeOnOverlayClick={false} closeOnEsc={false} size={size} onClose={closeModal} isOpen={isOpenModal} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent rounded={[0, 'md']}>
        <ModalCloseButton />
        <ModalBody maxH="max-content" bg={bg} rounded="md" py="5">
          <Box px={{ base: '2', md: '6' }}>
            <Box dir="row" textAlign="center" mb="5">
              <HStack
                _hover={{ cursor: 'pointer' }}
                onClick={handleOnClick}
                align="center"
              >
                <ArrowBackIcon />
                <Text fontWeight="bold" color={mode('gray.600', 'white')}>
                  Back
                </Text>
              </HStack>
              <Badge px="3" py="1" mt="-10" variant="solid" colorScheme="blue">
                <Text>Step 2</Text>
              </Badge>
              <Box w="100%" align="center">
                <Heading w="90%" level="4" fontWeight="bold" mt="6">
                  {microservicePackage?.description}
                </Heading>
              </Box>
            </Box>
            <Divider borderColor={borderColor} />
            <ConnectForm docs={docs} formData={formData} recipes={recipes} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConnectToMicroServiceModalStepTwo;
