/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { FC } from 'react';
import { IOptions, IRules } from '../shared/interface';

interface IProps {
  name: string
  label: string
  rules?: IRules
  placeholder?: string
  options: IOptions[]
  errors?: any
  register?: any
}

const FormSelect: FC<IProps> = ({
  name,
  label,
  rules,
  placeholder,
  options,
  errors,
  register,
}) => (
    <FormControl isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>{label}</FormLabel>
      <Select
        name={name}
        placeholder={placeholder}
        variant="outline"
        {...register(name, rules)}
      >
        {options.map(({ name: _name, value }) => (
          <option value={value}>{_name}</option>
        ))}
      </Select>
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );

export default FormSelect;
