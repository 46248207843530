import Cookies from "js-cookie";
import config from '../config'

export const capitalizeString = (word: string) => {
    const string = word?.toLowerCase();
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const getCookie = () => {
    const token = Cookies.getJSON(config.cookies.name);
    if (token) return token;
    return false;
};

export const clearCookies = () => {
    try {
      Cookies.remove(config.cookies.name, {
        domain: config.host
      });
    } catch (error) {
      console.error(error);
    }
  };

export const getAvatar = (firstName: string, lastName: string ) => `https://avatars.dicebear.com/api/identicon/${firstName}-${lastName}.svg`;