import {
  Badge,
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
  Text
} from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';
import TYPES from '../../../config/types';
import { ButtonRadioGroup } from './ButtonRadioGroup';
import useStore from '../../../logic/zustand';

const ConnectToMicroServiceModalStepOne: FC = () => {
  const {
    microservice,
    setSelect,
    openModal,
    isOpenModal,
    closeModal,
    clearWaiting
  } = useStore((store) => ({
    microservice: store.select[TYPES.SELECT.MICROSERVICE],
    setSelect: store.setSelect,
    openModal: store['modal/open'],
    isOpenModal: store.modal.isOpen,
    closeModal: store['modal/close'],
    clearWaiting: store.clearWaiting
  }));
  const [microservicePackage, setMicroservicePackage] = useState(
    microservice?.packageOptions?.[0]
  );
  const handleOnClick = () => {
    setSelect(TYPES.SELECT.MICROSERVICE_PACKAGE_OPTION, microservicePackage);
    setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, false);
    setSelect(TYPES.SELECT.CONNECTION_CREATE_PROCESSING_MESSAGE, false);
    clearWaiting(TYPES.WAITING.PROCESSING.CREATE_CONNECTION);
    clearWaiting(TYPES.WAITING.PROCESSING.CREATE_TEST_CONNECTION);
    openModal({ type: TYPES.MODALS.CONNECT_TO_MICRO_SERVICE_STEP_TWO });
  };

  const handleChange = (value) => {
    setMicroservicePackage(value);
  };

  const size = useBreakpointValue({md: '3xl'});
  const isCentered = useBreakpointValue({md: true});
  const bg = useColorModeValue('white', 'black');

  const initRef = useRef()

  return (
    <Modal scrollBehavior="inside" size={size} initialFocusRef={initRef} onClose={closeModal}  isCentered={isCentered} isOpen={isOpenModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody maxH="max-content" rounded="md" bg={bg} py="12">
          <Box px={{ base: '2', md: '6' }}>
            <Box textAlign="center" mb="10">
              <Badge px="3" py="1" variant="solid" colorScheme="blue">
               <Text> Step 1</Text>
              </Badge>
              <Heading size="lg" fontWeight="extrabold" mt="6" mb="2">
                Select one of the following actions
              </Heading>
            </Box>
            <Box maxW="2xl" mx="auto">
              <Box>
                {microservice?.packageOptions &&
                  microservice.packageOptions.length > 0 && (
                    <ButtonRadioGroup
                      onChange={(value) => handleChange(value)}
                      defaultValue={microservice.packageOptions[0]}
                      options={microservice.packageOptions.map(
                        (option, index) => ({
                            label: option.name,
                            description: option.description,
                            icon: option.logo,
                            isDisabled:
                              option.status !== 'enabled',
                            value: option,
                          })
                      )}
                    />
                  )}
                <Button
                ref={initRef}
                  onClick={handleOnClick}
                  mt={5}
                  colorScheme="blue"
                  isFullWidth
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConnectToMicroServiceModalStepOne;
