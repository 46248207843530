import { Box } from '@chakra-ui/react';
import useStore from '../../logic/zustand';

const ModalSkeleton = () => {
  const { content } = useStore((store) => ({
    content: store.modal.content,
  }));
  return content
};

export default ModalSkeleton;