
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Setter {
    [k: string]: any;
}


const modalInitialData = {
  isOpen: false,
  content: false,
  type: false
};

const initModalModel = (set, get, ModalComponents, MODAL_TYPES): Setter => {

  const modal = modalInitialData;

  const modals = Object.keys(MODAL_TYPES);

  const dict = modals.reduce((acc, value) => {
    const Content = ModalComponents[MODAL_TYPES[value]];
    acc[MODAL_TYPES[value]] = <Content/>;
    return acc;
  }, {});

  const open = ({ type }) => {
    set((state) => ({
      ...state,
      modal: {
        ...state.modal,
        isOpen: true,
        type,
        content: dict[type]
      },
    }));
  };

  const close = () => {
    set((state) => ({
      ...state,
      modal: {
        ...state.modal,
        type: false,
        isOpen: false,
        content: false
      },
    }));
  };

  return {
    modal,
    "modal/open": open,
    "modal/close": close,
  };
};

export default initModalModel;
