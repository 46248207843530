const models = {
    relations: {
        name: 'relations'
    },
    relationData: {
        name: 'relationData',
        actions: {
            list: '9047be75-024b-4c09-875d-f9ab0f4dc15d',
            update: '45707e4d-1891-4e35-91ce-60716bc0f739'
        },
    },
    users: {
        actions: {
            get: 'fd3515c5-71c5-432f-afab-01202070941d',
            update: 'e794fc11-81c3-456a-a47a-59b416a59fb8',
            updatePassword: 'f766d0d4-b5d1-4f1a-9ac2-a652f8172627'
        },
        name: 'users'
    },
    services: {
        actions: {
            list: 'eb503eef-c1c8-410a-ab96-426b4566ead8',
            joinWaitlist: '88950d2f-9443-42fb-b9ef-03ab2cb8fa87'
        },
        name: 'microservices'
    },
    microserviceClusters: {
        actions: {
            delete: '0b236267-77ec-4b70-8486-bea994ae7b9b',
            list: 'b52420d5-e7cb-4718-8464-33e1529b5552',
            sync: '205ea251-ab9e-44ba-b9b2-19b13e288f12'
        },
        name: 'microservice-clusters'
    },
    search: {
        actions: {
            list: 'f1a2782d-3921-44d7-a36d-1250a99ff158'
        },
        name: 'search'
    },
    recipes: {
        actions: {
            list: 'df7e9c36-ac6c-4b81-9000-9638328513f0',
            delete: '88a9c72a-4a29-4750-b2cd-64fc28363c18',
            create: 'ac35e076-ed31-47b6-9b25-1a4e25ee5ac4',
            fork: 'bace0c98-324b-4990-a443-4e599fefe4d0',
            addTags: '35ceb0ce-d1c7-46a7-b580-41f481cf7669'
        },
        name: 'recipes'
    }
};

export default models;