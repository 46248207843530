import {
  Box,
  SimpleGrid,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput, FormSelect, FormButton, FormCheckbox, FormNumberInput, PasswordInput, FormRadio, FormSwitch, FormTextArea } from '../components';
import TYPES from '../config/types';
import { IObject, IForm, IButton } from '../shared/interface';

interface IProps {
  dynamicFormData: IForm[];
  defaultValues?: IObject;
  defaultWrapperStyle?: IObject;
  buttonStyle?: IButton;
  showButton?: boolean;
  handleOnSubmit: (values: string) => void
}
const CustomFormHook = ({
  dynamicFormData,
  defaultValues,
  defaultWrapperStyle,
  buttonStyle,
  showButton,
  handleOnSubmit
}: IProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    setValue,
    getValues,
    control,
  } = useForm({ defaultValues, mode: 'onBlur', reValidateMode: 'onBlur' });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => handleOnSubmit(values);

  const setValues = (payload: IObject) => {
    const currentValues = getValues();
    const updatedValues = { ...currentValues, ...payload };
    const keys = Object.keys(updatedValues);
    keys.forEach((key) => setValue(key, updatedValues[key]));
  };

  const setSingleValue = (payload: IObject) => {
    const keys = Object.keys(payload);
    if (keys.length > 1) {
      throw Error('setValue accepts only one property');
    }
    setValue(keys[0], payload[keys[0]]);
  };

  const Form: FC = () => (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box w="100%">
          <SimpleGrid gap={4} columns={1} {...defaultWrapperStyle}>
            {dynamicFormData.map(
              ({
                name,
                label,
                rules,
                placeholder,
                type,
                options,
                min,
                max,
                step,
              }) => (
                  <>
                    {type === TYPES.FORM_ELEMENTS.INPUT && (

                        <FormInput
                          name={name}
                          label={label}
                          rules={rules}
                          errors={errors}
                          register={register}
                          placeholder={placeholder}
                        />
                    )}
                    {type === TYPES.FORM_ELEMENTS.SELECT && options && (
                      <FormSelect
                        name={name}
                        label={label}
                        rules={rules}
                        errors={errors}
                        register={register}
                        options={options}
                        placeholder={placeholder}
                      />
                    )}
                    {type === TYPES.FORM_ELEMENTS.RADIO && options && (
                      <FormRadio
                        name={name}
                        label={label}
                        rules={rules}
                        errors={errors}
                        register={register}
                        options={options}
                        control={control}
                      />
                    )}
                    {type === TYPES.FORM_ELEMENTS.CHECKBOX && options && (
                      <FormCheckbox
                        name={name}
                        label={label}
                        rules={rules}
                        errors={errors}
                        register={register}
                        options={options}
                        control={control}
                      />
                    )}
                    {type === TYPES.FORM_ELEMENTS.SWITCH && (
                      <FormSwitch
                        name={name}
                        label={label}
                        rules={rules}
                        errors={errors}
                        register={register}
                        control={control}
                      />
                    )}
                    {type === TYPES.FORM_ELEMENTS.PASSWORD && (
                      <PasswordInput
                        name={name}
                        label={label}
                        rules={rules}
                        errors={errors}
                        register={register}
                        placeholder={placeholder}
                      />
                    )}
                    {type === TYPES.FORM_ELEMENTS.NUMBER && (
                      <FormNumberInput
                        name={name}
                        label={label}
                        rules={rules}
                        errors={errors}
                        register={register}
                        placeholder={placeholder}
                        min={min}
                        max={max}
                        step={step}
                      />
                    )}
                    {type === TYPES.FORM_ELEMENTS.TEXTAREA && (
                      <FormTextArea
                        name={name}
                        label={label}
                        rules={rules}
                        errors={errors}
                        register={register}
                        placeholder={placeholder}
                      />
                    )}
                  </>
                )
            )}
          </SimpleGrid>
          {showButton && <Box mt={4}>
          <FormButton isLoading={isSubmitting} isFullWidth={buttonStyle?.isFullWidth} label={buttonStyle?.label} colorScheme={buttonStyle?.colorScheme} type="submit"/>
          </Box>}
        </Box>
      </form>
    );

  return {
    Form,
    setValues,
    setValue: setSingleValue,
    onSubmit,
    getValues,
    isSubmitting,
    isValid,
    handleSubmit
  };
};

export default CustomFormHook;
