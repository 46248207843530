import {
  Box,
  chakra,
  Text,
  useRadio,
  UseRadioProps,
  VStack,
  useColorModeValue as mode,
  useId,
  Badge,
  Image,
} from '@chakra-ui/react';
import * as React from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

const RadioBox = chakra('div', {
  baseStyle: {
    borderWidth: '2px',
    px: '6',
    pt: '12',
    pb: '8',
    borderRadius: 'md',
    cursor: 'pointer',
    transition: 'all 0.2s',
    minH: "250px",
    _focus: { shadow: 'outline' },
  },
});

const CheckboxIcon = (props: { checked: boolean }) => (
  <Box
    fontSize="4xl"
    color={props.checked ? 'blue.600' : mode('gray.300', 'whiteAlpha.400')}
  >
    {props.checked ? (
      <MdRadioButtonChecked size="24px" />
    ) : (
      <MdRadioButtonUnchecked size="24px" />
    )}
  </Box>
);

interface ButtonRadioProps extends UseRadioProps {
  icon: string;
  label: string;
  description: string;
}

export const ButtonRadio = (props: ButtonRadioProps) => {
  const { label, icon, description, isDisabled } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useRadio(
    props
  );
  const id = useId();

  const checkedStyles = {
    bg: mode('blue.50', 'rgb(0 31 71)'),
    borderColor: 'blue.600',
  };

  const disabledStyles = {
    bg: mode('white', 'gray.600'),
    cursor: "no-drop",
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label style={{ width: '100%' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox
        _disabled={isDisabled ? disabledStyles : {}}
        {...getCheckboxProps()}
        _checked={checkedStyles}
        id={id}
        borderColor={mode('gray.50', 'whiteAlpha.400')}
      >
        <VStack spacing="4">
          <VStack textAlign="center">
            {isDisabled && (
              <Badge
                mt={-10}
                mr={-4}
                marginLeft="auto"
                variant="subtle"
                colorScheme="green"
                px={2}
                rounded="md"
                textTransform="none"
              >
                Coming soon
              </Badge>
            )}
            <Box
              aria-hidden
              fontSize="4xl"
              mb="3"
              color={state.isChecked ? 'blue.600' : undefined}
            >
              <Image h="50px" w="50px" src={icon} />
            </Box>
            <Text fontWeight="extrabold" fontSize="xl">
              {label}
            </Text>
            <Text fontSize="md">{description}</Text>
          </VStack>
          <CheckboxIcon checked={state.isChecked} />
        </VStack>
      </RadioBox>
    </label>
  );
};
