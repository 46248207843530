import TYPES from '../../config/types';
import { models, useGet } from '../queries';

const useGetUserFlow = () => {

  const { data, status } = useGet({
    triggerId: models.users.actions.get,
    queryKey: `${models.users.name  }-get`,
  });

  return { data, status };
};

export default useGetUserFlow;
