import { useMutation, useQueryClient } from 'react-query';
import dispatchRecipe from '../utils/dispatchRecipe';

interface IMutate {
  queryKey: string;
  triggerId: string;
  env?: string
}

const useMutate = (props: IMutate) => {
  const { queryKey, triggerId, env, ...rest } = props;

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload) => dispatchRecipe({ triggerId, payload, env }),
    {
    //   onMutate: (variables) => {},
      onError: (error, variables, context) => {
        console.error(`useMutate with model ${queryKey} failed`);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(queryKey);
      },
    //   onSettled: (data, error, variables, context) => {},
    }
  );

  // TODO: check if this works without the await
  // eslint-disable-next-line no-return-await
  const mutate = async (payload) => await mutation.mutateAsync(payload);

  return mutate;
};

export default useMutate;
