/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorModeValue,
  useBreakpointValue,
  Box,
  Textarea,
  VStack,
  Select,
  Flex,
  ModalFooter,
  HStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import useStore from '../../../logic/zustand';
import useCreateFlowInteraction from '../../../logic/interactions/useCreateFlowInteraction';

// Only REST triggers allowed for now
const options = [
  {
    value: 'rest',
    label: 'REST',
  },
];

const CreateFlow = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { isOpenModal, closeModal } = useStore((store) => ({
    isOpenModal: store.modal.isOpen,
    closeModal: store['modal/close'],
  }));

  const bg = useColorModeValue('white', 'gray.800');
  const size = useBreakpointValue({ md: '2xl' });
  const isCentered = useBreakpointValue({ md: true });

  const { create, isLoading } = useCreateFlowInteraction();

  const onSubmit = async (value) => {
    await create(value);
  };

  return (
    <Modal
      size={size}
      onClose={closeModal}
      isOpen={isOpenModal}
      isCentered={isCentered}
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody maxH="max-content" rounded="md" pt={12} pb={4}>
            <Box py={0} alignItems="center">
              <VStack spacing="4" w="100%">
                <FormControl isInvalid={errors.name}>
                  <Input
                    autoFocus
                    {...register('name', {
                      required: 'Name is required',
                    })}
                    variant="filled"
                    size="lg"
                    placeholder="Flow name"
                    fontWeight="bold"
                    fontSize="2xl"
                    name="name"
                    autoComplete="off"
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <Textarea
                    name="description"
                    variant="filled"
                    placeholder="Description"
                    defaultValue=""
                    size="md"
                    rows={4}
                    {...register('description')}
                  />
                </FormControl>
                <FormControl id="triggerType">
                  <FormLabel>Select trigger type</FormLabel>
                  <Select
                    name="trigger"
                    defaultValue={options[0].value}
                    variant="filled"
                    disabled
                  >
                    {options.map(({ label, value }) => (
                      <option key={`${label}.${value}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack justify="space-between" width="100%">
              <Button
                variant="link"
                colorScheme="gray"
                size="lg"
                mr={3}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <HStack spacing="5">
                <Button
                  variant="solid"
                  colorScheme="blue"
                  type="submit"
                  size="lg"
                >
                  Create
                </Button>
              </HStack>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateFlow;
