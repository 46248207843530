import { useToast } from '@chakra-ui/react';
import { TYPES } from '../../index';
import { useCreateFlow } from '../hooks/useCreateFlow';
import useStore from '../zustand'
import config from '../../config'

function useCreateFlowInteraction () {
  const toast = useToast()
  const { closeModal } = useStore((store) => ({
    closeModal: store['modal/close']
  }))
  const { createFlow, error, isLoading } = useCreateFlow();
  const create = async (payload) => {
    try {
      const data = await createFlow(payload);
      toast({
        title: "A new flow has been created.",
        status: "success",
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href=`${config.builderUrl}/board/${data?._id}`
      }, 2000)
    } catch (e) {
      toast({
        title: "A new flow could not be created.",
        status: "error",
        duration: 5000,
        position: 'top',
        isClosable: true,
      })
    } finally {
      closeModal({type: TYPES.MODALS.CREATE_FLOW})
    }
  }

  return { create, error, isLoading };
}

export default useCreateFlowInteraction
