/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, CheckboxGroup } from '@chakra-ui/checkbox';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { HStack, Stack } from '@chakra-ui/layout';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { FC, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { IOptions, IRules } from '../shared/interface';

interface IProps {
  name: string;
  options: IOptions[];
  rules?: IRules;
  register: any;
  control: any;
  errors?: any;
  label?: string;
}

const FormCheckbox: FC<IProps> = ({
  name,
  options,
  rules,
  register,
  errors,
  label,
  control,
}) => (
    <FormControl  isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>{label}</FormLabel>
      <HStack spacing={4}>
      {options.map(({ name: optionName, value }, i) => 
        //   console.log(`${optionName}${i}`, "<======== what?")
         (
          <Controller
            name={optionName}
            control={control}
            // {...register(name, rules)}
            render={({ field: { onChange, value: isChecked, ref } }) => (
              <Checkbox
                onChange={onChange}
                ref={ref}
                isChecked={isChecked}
              >
                {optionName}
              </Checkbox>
            )}
          />
        )
      )}
      </HStack>
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );

export default FormCheckbox;
