/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
  } from '@chakra-ui/react';
  import { HiOutlineDotsVertical } from 'react-icons/hi';
  import { MdDelete } from 'react-icons/md';
  import { ImMagicWand } from 'react-icons/im';
import useGenerateCrudFlow from '../../../logic/flows/useGenerateCrudFlow';
import useStore from '../../../logic/zustand';
import TYPES from '../../../config/types';


interface IProps {
  record: any;
}

const DropdownMenu = (props: IProps) => {
  const { record } = props;
  const generateCrud = useGenerateCrudFlow({
    triggerId: record?.actions?.createCrud?.recipeTriggerId
  });

  const { openModal, setSelect } = useStore((store) => ({
    openModal: store['modal/open'],
    setSelect: store.setSelect
  }));

  const handleDelete = () => {
    openModal({type: TYPES.MODALS.DELETE_RELATION});
    setSelect(TYPES.SELECT.APPEND, record?.append)
    setSelect(TYPES.SELECT.RELATION_DELETE_TRIGGER_ID, record?.actions?.deleteRelation?.recipeTriggerId)
    setSelect(TYPES.SELECT.RELATION_NAME, record?.serviceName)
  };

  return (
      <Menu>
        <MenuButton>
          <HiOutlineDotsVertical />
        </MenuButton>
        <MenuList>
          {record?.enabled.includes('createCrud') && <MenuItem
          onClick={() => generateCrud({serviceName: record?.serviceName, ...record?.append })}
            icon={<ImMagicWand/>}
          >
            Generate crud
          </MenuItem>}
          {record?.enabled.includes('deleteRelation') && <MenuItem
          onClick={handleDelete}
          // onClick={() => deleteRelation({serviceId: record?.append?.microserviceId})}
          // onClick={() => deleteRelation({...record?.append})}
            icon={<MdDelete />}
          >
            Delete
          </MenuItem>}
        </MenuList>
      </Menu>
    );
  };
  
  export default DropdownMenu;
  