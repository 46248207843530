/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Stack } from '@chakra-ui/layout';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { FC, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { IOptions, IRules } from '../shared/interface';

interface IProps {
  name: string;
  options: IOptions[];
  rules?: IRules;
  register: any;
  control: any;
  errors?: any;
  label?: string;
}

const FormRadio: FC<IProps> = ({
  name,
  options,
  rules,
  register,
  errors,
  label,
  control,
}) => (
    <FormControl isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>{label}</FormLabel>
      <Controller
      name={name}
      control={control}
      render={({field: {value: checkedValue, onChange, ref}}) => (
          <RadioGroup onChange={onChange} value={checkedValue || options?.[0]?.value}>
              <Stack direction='row' spacing='4'>
              {options.map(({name: _name, value}) => (<Radio name={_name} value={value}  ref={ref}>{name}</Radio>))}
              </Stack>
          </RadioGroup>
      )}
      />
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );

export default FormRadio;
