/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useQuery } from 'react-query';
import dispatchRecipe from '../utils/dispatchRecipe';

export interface IObject {
  [K: string]: any;
}

interface IProps {
  queryKey: string;
  triggerId: string;
  query?: IObject;
  page?: number;
  pageSize?: number;
  sort?: string;
  restOfParams?: IObject;
  fields?: string[];
  [K: string]: any
}

const useList = (props: IProps) => {
  const {
    queryKey,
    triggerId,
    env,
    query,
    page = 1,
    pageSize = 10,
    sort = '-createdAt',
    fields,
    restOfParams,
    ...rest
  } = props;

  const [_page, setPage] = useState<number>(page);
  const [_sort, setSort] = useState(sort);

  const { data, status, error } = useQuery(
    [queryKey, _page, query, _sort, fields, env, {...rest}],
    () =>
      dispatchRecipe({
        triggerId,
        env,
        payload: { query, page: _page, pageSize, sort: _sort, fields, ...rest},
      }),
    { retry: false, refetchOnWindowFocus: true, staleTime: 0, ...restOfParams }
  );

  const hasNextPage = data?.page < data?.totalPages;
  const hasPreviousPage = data?.page !== 1;

  const goToPage = (newPage) => {
    if (newPage > 0 && newPage <= data?.totalPages) {
      setPage(newPage);
    }
  };

  const getPage = (num = 1) => {
    if (num === 1 && !hasNextPage) {
      return;
    }
    if (num === -1 && !hasPreviousPage) {
      return;
    }
    setPage(_page + num);
  };

  const handleSort = (field) => setSort(field);


  return {
    rows: data?.rows,
    status,
    error,
    hasNextPage,
    hasPreviousPage,
    back: () => getPage(-1),
    next: () => getPage(1),
    goToPage,
    sort: _sort,
    handleSort,
    setPage,
    page: data?.page,
    pageSize: data?.pageSize,
    total: data?.total,
    totalPages: data?.totalPages,
    data
  };
};

export default useList;
