/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@chakra-ui/button';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { FC, useState } from 'react';
import { IRules } from '../shared/interface';

interface IProps {
  name: string;
  label?: string;
  placeholder?: string;
  register?: any;
  errors?: any;
  rules?: IRules;
}

const PasswordInput: FC<IProps> = ({
  name,
  label,
  placeholder,
  register,
  errors,
  rules,
}) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  return (
    <FormControl isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>{label}</FormLabel>
      <InputGroup size="md">
        <Input
          name={name}
          pr="4.5rem"
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          {...register(name, rules)}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClick}>
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default PasswordInput;
