import useSearchFlows from '../hooks/useSearchFlows';

function useSearchFlowsInteraction (payload) {

  const { data, status } = useSearchFlows(payload);

  return { rows: data?.rows, status }

};

export default useSearchFlowsInteraction;
