const activeEnv = process.env.NEXT_PUBLIC_ENV || 'development';

const envFlags = {
  staging: 'staging-',
  beta: 'beta-',
  production: '',
};

const hostList = {
  development: 'localhost',
  staging: 'buildable.io',
  beta: 'buildable.dev',
  production: 'buildable.dev',
};

const welcomeDomain = {
  development: 'http://localhost:4501',
  staging: 'https://staging-welcome.buildable.io/',
  beta: 'https://welcome.buildable.dev',
  production: 'https://welcome.buildable.dev',
};

const mainUrl = 'app.buildable.dev';

const builderDomain = {
  development: 'http://localhost:4200',
  staging: `https://${envFlags.staging}${mainUrl}`,
  beta: `https://${envFlags.beta}${mainUrl}`,
  production: `https://${envFlags.production}${mainUrl}`,
};

const flowSecureCallerEndpoints = {
  development: 'https://development-api.buildable.io/secure-flow/v1/call/',
  staging: `https://staging-api.buildable.io/secure-flow/v1/call/`,
  beta: `https://beta-api.buildable.dev/secure-flow/v1/call/`,
  production: `https://api.buildable.dev/secure-flow/v1/call/`,
};

export const flowSecureCallerEndpoint = flowSecureCallerEndpoints[activeEnv];

const flowCallerEndpoints = {
  development: 'https://development-api.buildable.io/flow/v1/call/',
  staging: `https://staging-api.buildable.io/flow/v1/call/`,
  beta: `https://beta-api.buildable.dev/flow/v1/call/`,
  production: `https://api.buildable.dev/flow/v1/call/`,
};

export const flowCallerEndpoint = flowCallerEndpoints[activeEnv];

const domains = {
  onboarding: {
    development: 'http://localhost:4201',
    staging: 'https://staging-welcome.buildable.io',
    beta: 'https://beta-welcome.buildable.dev',
    production: 'https://welcome.buildable.dev',
  },
  microservices: {
    development: 'http://localhost:4502',
    staging: 'https://staging-app.buildable.io',
    beta: 'https://beta-app.buildable.dev',
    production: 'https://app.buildable.dev',
  },
  canvas: {
    development: 'http://localhost:4501',
    staging: 'https://staging-canvas.buildable.io',
    beta: 'https://beta-canvas.buildable.dev',
    production: 'https://canvas.buildable.dev',
  },
};

const welcomeUrl = welcomeDomain[activeEnv];
const builderUrl = builderDomain[activeEnv];
export const microservicesUrl = domains.microservices[activeEnv];

const host = hostList[activeEnv];

export default {
  cookies: {
    name: 'buildable',
    visitor: 'buildable',
    expiry: 200,
    domain: host,
  },
  microservicesUrl,
  welcomeUrl,
  builderUrl,
  activeEnv,
  host,
};
