/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Stack, Text, Flex, Skeleton, useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import QueryForm from './QueryForm';
import useListRelationsData from '../../../logic/flows/useListRelationsData';
import { TablePagination } from '../../table/TablePagination';
import EmptyState from './EmptyState';
import useStore from '../../../logic/zustand';
import TYPES from '../../../config/types';

const JsonViewerWithNoSSR = dynamic(() => import('./jsonViewer'), {
  ssr: false,
});

const ControlledQuery = () => {
  const router = useRouter();
  const [query, setQuery] = useState<any>();
  const [fields, setFields] = useState<any>();
  const [queried, setQueried] = useState<boolean>(false);
  const toast = useToast();

  const { env } = useStore((store) => ({
    env: store.select[TYPES.SELECT.ENVIRONMENT_STATE] || TYPES.ENVIRONMENT_VARIABLES.TEST
  }));

  const check = (input) => {
    try {
      const response = JSON.parse(input);
      return response;
    } catch {
      toast({
        title: 'Invalid JSON input',
        description: 'Check your JSON syntax',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return null;
    }
  };

  const {
    rows,
    status,
    page,
    totalPages,
    next,
    back,
    goToPage,
    handleSort,
  } = useListRelationsData({
    relation: router?.query?.service,
    env,
    pageNumber: 1,
    pageSize: 5,
    query,
    fields,
  });

  const handleSubmit = (values) => {
    setQueried(true);
    const { query: _query, fields: _fields, sort } = values;
    const parsedQuery = check(_query);
    const parsedFields = check(_fields);
    setFields(parsedFields?.length > 0 ? parsedFields : undefined)
    setQuery((parsedQuery && Object.keys(parsedQuery)?.length > 0) ? parsedQuery : undefined)
    handleSort(sort.replace(/['"]+/g, ''));
  };

  if (status !== 'loading' && rows?.length === 0 && !queried) return <EmptyState />;
  return (
    <>
      <Stack
        boxShadow="md"
        rounded="md"
        p={3}
        direction="column"
        w="100%"
        spacing={3}
      >
        <Text fontWeight="bold" fontSize="md">
          Query your data
        </Text>
        <QueryForm isSubmitting={status} onEvent={handleSubmit} />
      </Stack>
      <Box w="100%" pt={10}>
        {status === 'loading' ? (
          <Skeleton w="100%" h="500px" />
        ) : (
          <JsonViewerWithNoSSR relation={router?.query?.service} rows={rows} />
        )}
        <Flex justify="flex-end" w="100%" mt={5}>
          {totalPages > 1 && (
            <TablePagination
              totalPages={totalPages}
              next={next}
              back={back}
              page={page}
              goToPage={goToPage}
            />
          )}
        </Flex>
      </Box>
    </>
  );
};

export default ControlledQuery;
