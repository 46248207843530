/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Textarea } from '@chakra-ui/textarea';
import { FC } from 'react';
import { IRules } from '../shared/interface';

interface IProps {
  name: string;
  label?: string;
  placeholder?: string;
  rules?: IRules;
  errors?: any;
  register?: any;
}

const FormTextArea: FC<IProps> = ({
  name,
  label,
  placeholder,
  rules,
  errors,
  register,
}) => (
    <FormControl isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>{label}</FormLabel>
      <Textarea {...register(name, rules)} placeholder={placeholder}/>
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );

export default FormTextArea;
