const TYPES = {
    FORM_ELEMENTS: {
        INPUT: "input",
        BUTTON: "button",
        NUMBER: "number",
        PASSWORD: "password",
        RADIO: "radio",
        SELECT: "select",
        TEXTAREA: "textarea",
        SWITCH: "switch",
        CHECKBOX: "checkbox"
    }
}

export default TYPES