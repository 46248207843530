/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  FormLabel,
  Stack,
  Textarea,
  Flex,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';
import { useForm } from 'react-hook-form';
import { BsFillPlayFill } from 'react-icons/bs';

const values = {
  query: '{}',
  fields: '[]',
  sort: '',
};

interface IProps {
  onEvent: (values: any) => void;
  isSubmitting: string;
}

const QueryForm = (props: IProps) => {
  const { onEvent, isSubmitting } = props;
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: values,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const onSubmit = (vals) => {
    const {
      query = '{}',
      fields = '[]',
      sort = '-createdAt' } = vals;
    const updatedValues = {
      ...vals,
      query,
      fields,
      sort,
    };
    onEvent(updatedValues);
  };

  const bg = useColorModeValue('gray.800', 'gray.100');
  const color = useColorModeValue('gray.100', 'gray.800');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack w="100%" direction="column">
        <FormControl>
          <FormLabel fontSize="sm" htmlFor="query">
            Query
          </FormLabel>
          <Textarea
            {...register('query')}
            id="query"
            as={ResizeTextarea}
            overflow="hidden"
            minRows={5}
            minH="unset"
            resize="none"
          />
        </FormControl>
        <Stack direction={{ base: 'column', md: 'row' }} w="100%">
          <Flex justify="start" w={{ base: '100%', md: '50%' }}>
            <FormControl>
              <FormLabel fontSize="sm" htmlFor="fields">
                Fields
              </FormLabel>
              <Textarea
                {...register('fields')}
                id="fields"
                as={ResizeTextarea}
                overflow="hidden"
                minRows={1}
                minH="unset"
                resize="none"
              />
            </FormControl>
          </Flex>
          <Flex ml={3} justify="end" w={{ base: '100%', md: '50%' }}>
            <FormControl>
              <FormLabel fontSize="sm" htmlFor="sort">
                Sort
              </FormLabel>
              <Textarea
                {...register('sort')}
                id="sort"
                as={ResizeTextarea}
                overflow="hidden"
                minRows={1}
                minH="unset"
                resize="none"
              />
            </FormControl>
          </Flex>
        </Stack>
      </Stack>
      <Flex w="100%" justify="flex-end">
        <Button
          leftIcon={<BsFillPlayFill />}
          fontWeight="bold"
          disabled={isSubmitting === 'loading'}
          w="100px"
          mt={4}
          bg={bg}
          _hover={{
            color,
          }}
          color={color}
          // isLoading={isProcessingUpdate}
          type="submit"
        >
          <Text fontSize="lg">Run</Text>
        </Button>
      </Flex>
    </form>
  );
};

export default QueryForm;
