import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
  Text,
  Heading,
  Button,
} from '@chakra-ui/react';
import TYPES from '../../../config/types';
import useStore from '../../../logic/zustand';
import useDeleteFlowInteraction from '../../../logic/interactions/useDeleteFlowInteraction';

const DeleteModal = () => {
  const { flowDelete, isLoading } = useDeleteFlowInteraction();
  const { isOpenModal, closeModal, record, waiting } = useStore((store) => ({
    isOpenModal: store.modal.isOpen,
    closeModal: store['modal/close'],
    record: store.select[TYPES.SELECT.RECIPE_RECORD],
    waiting: store.waiting[TYPES.WAITING.PROCESSING.DELETE_RECIPE],
  }));

  let title = `Are you sure you want to delete ${record?.name}?`;
  let subTitle = 'Deleting a flow cannot be reversed. It also deletes any deployed version if it exists.';
  let isDeleteDisabled = false;

  if (record?.frontend?.features?.actions?.delete?.enabled === false) {
    title = record?.frontend?.features?.actions?.delete?.spec?.title;
    subTitle = record?.frontend?.features?.actions?.delete?.spec?.subTitle;
    isDeleteDisabled = true
  }

  const size = useBreakpointValue({ md: 'lg' });
  const isCentered = useBreakpointValue({ md: true });
  const bg = useColorModeValue('white', 'black');
  return (
    <Modal
      size={size}
      onClose={closeModal}
      isOpen={isOpenModal}
      isCentered={isCentered}
    >
      <ModalOverlay />
      <ModalContent rounded={[0, 'md']}>
        <ModalCloseButton />
        <ModalBody maxH="max-content" rounded="md" bg={bg} py="5">
          <Box p={5} alignItems="center">
            <Box w="100%">
              <Center>
                <Heading textAlign="center" size="sm">{title}</Heading>
              </Center>
            </Box>
            <Text mt={3} size="md" textAlign="center">
             {subTitle}
            </Text>
            <Button
              isDisabled={isDeleteDisabled}
              isLoading={isLoading}
              onClick={() => flowDelete(record?._id)}
              mt={5}
              minW="100%"
              colorScheme="red"
            >
              Delete
            </Button>
            <Button
              onClick={() => closeModal()}
              mt={5}
              minW="100%"
              colorScheme="gray"
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
