import { useMutation, useQueryClient } from 'react-query';
import { apiWithToken } from '../utils/dispatchRecipe';
import { apiKeys } from '../apiKeys';
import reactQueryKeys from '../queryKeys';

export const useDeleteFlow = () => {
  const queryClient = useQueryClient();

  const deleteFlow = useMutation(
    (payload: {
      id: string
    }) => apiWithToken({
      url: apiKeys.delete,
      method: 'POST',
      payload
    }),
    {
      onSuccess: (data) => {
        // sync with BE
        queryClient.invalidateQueries([reactQueryKeys.flowList]);
      },
      onError: (data, ...rest) =>
        queryClient.invalidateQueries([reactQueryKeys.flowList]),
    }
  )

  return { deleteFlow: deleteFlow.mutateAsync, error: deleteFlow.error, isLoading: deleteFlow.isLoading }
};
