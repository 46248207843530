import {
  Box,
  Flex,
  Stack,
  useColorModeValue,
  useColorModeValue as mode,
  Text,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import router from 'next/router';
import { NavItem } from '../NavItem';
import { useMobileMenuState } from '../useMobileMenuState';
import useStore from '../../../logic/zustand';
import TYPES from '../../../config/types';
import { MobileMenuButton } from '../MobileMenuButton';

const SettingsLayout = ({ children }) => {
  const borderColor = useColorModeValue('gray.50', 'gray.800');
  const bgColor = useColorModeValue('white', 'black');
  const { isOpen, toggle } = useMobileMenuState();

  const initState = {
    profile: false,
    preferences: false,
    invite: false,
  };

  const [state, setState] = useState({ ...initState, profile: true });

  const { setSelect } = useStore((store) => ({
    setSelect: store.setSelect,
  }));

  return (
    <Flex overflowY="auto" direction="column">
      <Box overflow="hidden" sx={{ '--sidebar-width': '16rem' }}>
        <Box
          bg={bgColor}
          h="full"
          as="nav"
          display="block"
          flex="1"
          width="var(--sidebar-width)"
          left="0"
          py="5"
          px="3"
          color={mode('gray.800', 'gray.50')}
          position="fixed"
          borderRightColor={borderColor}
          borderRightWidth="1px"
        >
          <Box h="100%" fontSize="sm" lineHeight="tall">
            <Flex position="relative" h="full" direction="column">
              <Box>
                <Flex cursor="pointer" onClick={() => {router.push(TYPES.ROUTES.MICROSERVICES, undefined, { shallow: true }); setSelect(TYPES.SELECT.SETTINGS, TYPES.SETTINGS_TYPES.PROFILE)}} justify="start" align="center">
                  <ChevronLeftIcon color={mode('gray.800', 'gray.100')} fontSize="xl" />
                  <Text fontWeight="bold" color={mode('gray.800', 'gray.100')} ml={2} size="md">
                    Settings
                  </Text>
                </Flex>

                <Stack spacing="1" flex="1" overflow="auto" pt="10">
                  <Flex
                    onClick={() => {
                      setSelect(
                        TYPES.SELECT.SETTINGS,
                        TYPES.SETTINGS_TYPES.PROFILE
                      );
                      setState({ ...initState, profile: true });
                    }}
                  >
                    <NavItem active={state.profile} label="Profile" />
                  </Flex>
                  <Flex
                    onClick={() => {
                      setSelect(
                        TYPES.SELECT.SETTINGS,
                        TYPES.SETTINGS_TYPES.PREFERENCES
                      );
                      setState({ ...initState, preferences: true });
                    }}
                  >
                    <NavItem active={state.preferences} label="Preferences" />
                  </Flex>
                  <Flex
                    onClick={() => {
                      setSelect(
                        TYPES.SELECT.SETTINGS,
                        TYPES.SETTINGS_TYPES.INVITE
                      );
                      setState({ ...initState, invite: true });
                    }}
                  >
                    {/*<NavItem active={state.invite} label="Invite" />*/}
                  </Flex>
                </Stack>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box
          flex="1"
          h="100%"
          p={{ base: '0', md: '0' }}
          marginStart={{ md: 'var(--sidebar-width)' }}
          position="relative"
          left={isOpen ? 'var(--sidebar-width)' : '0'}
          transition="left 0.2s"
        >
          <Box
            w="100%"
            align="center"
            bg={mode('white', 'black')}
            minH="100vh"
          >
            <Flex
              align="start"
              maxW="640px"
              w="100%"
              direction="column"
              overflow="auto"
              height="full"
            >
              <Flex  w="full" align="start" pt={{base: 5, md: 0}} px={{base: 5, md: 0}}>
                <MobileMenuButton onClick={toggle} isOpen={isOpen} />
              </Flex>
              <Box align="start" w="full" px={5} py={{base: 5, md: 20}}>
                {children}
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default SettingsLayout;
