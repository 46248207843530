import { ImMagicWand } from 'react-icons/im';
import { FaShapes } from 'react-icons/fa';
import { BiCog } from 'react-icons/bi';
import {
  Box,
  Flex,
  Stack,
  useColorModeValue as mode,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { SearchIcon } from '@chakra-ui/icons';
import { MobileMenuButton } from './MobileMenuButton';
import { NavBreadcrumb } from './NavBreadcrumb';
import { NavItem } from './NavItem';
import { useMobileMenuState } from './useMobileMenuState';
import { UserProfile } from './UserProfile';
import SettingsLayout from './settings';
import TYPES from '../../config/types';
import SearchLayout from './search';
import CreateRecipeButton from '../shared/CreateRecipeButton';
import QueryLayout from './microserviceQuery';

const pathMap = {
  [TYPES.ROUTES.RECIPES]: 'Logic Flows',
  [TYPES.ROUTES.MICROSERVICES]: 'Microservices'
}

const Layout = ({ children }) => {
  const borderColor = useColorModeValue('gray.50', 'gray.800');
  const bgColor = useColorModeValue('white', 'black');
  const router = useRouter();

  const { isOpen, toggle } = useMobileMenuState();

  if (router.pathname === TYPES.ROUTES.SEARCH) {
    return <SearchLayout>{children}</SearchLayout>;
  }

  if (router.pathname === TYPES.ROUTES.SETTINGS) {
    return <SettingsLayout>{children}</SettingsLayout>;
  }

  if (router.pathname === TYPES.ROUTES.MICROSERVICE_SERVICE_QUERY) {
    return <QueryLayout>{children}</QueryLayout>
  }

  let links = [
    {
      name: pathMap[router.pathname],
      href: null,
    },
  ];

  if (router.pathname === TYPES.ROUTES.MICROSERVICE_DETAILS) {
    const microserviceName = String(router?.query?.id);
    links = [
      {
        name: 'Microservices',
        href: TYPES.ROUTES.MICROSERVICES
      },
      {
        name: microserviceName,
        href: null,
      },
    ];
  }

  return (
    <Flex overflowY="auto" direction="column">
      <Box overflow="hidden" sx={{ '--sidebar-width': '16rem' }}>
        <Box
          bg={bgColor}
          h="full"
          as="nav"
          display="block"
          flex="1"
          width="var(--sidebar-width)"
          left="0"
          py="5"
          px="3"
          color={mode('gray.800', 'gray.50')}
          position="fixed"
          borderRightColor={borderColor}
          borderRightWidth="1px"
        >
          <Box h="100%" fontSize="sm" lineHeight="tall">
            <Flex position="relative" h="full" direction="column">
              <Box>
                <UserProfile />
                <Stack spacing="8" flex="1" overflow="auto" pt="8">
                  <CreateRecipeButton />
                  <Stack spacing="1">
                    <NavItem
                      active={
                        router.pathname === TYPES.ROUTES.SEARCH
                      }
                      href={TYPES.ROUTES.SEARCH}
                      icon={<SearchIcon  />}
                      label="Search"
                    />
                    <NavItem
                      active={
                        !!(router.pathname === '/' ||
                        router.pathname.includes('microservice'))
                      }
                      href={TYPES.ROUTES.MICROSERVICES}
                      icon={<FaShapes  />}
                      label="Microservices"
                    />

                    <NavItem
                      active={
                        router.pathname === TYPES.ROUTES.RECIPES
                      }
                      href={TYPES.ROUTES.RECIPES}
                      icon={<ImMagicWand  />}
                      label="Logic Flows"
                    />
                  </Stack>
                </Stack>
              </Box>
              <Box>
                <Stack
                  w="100%"
                  bottom="0px"
                  position="absolute"
                  spacing="1"
                >
                  <NavItem
                    href="/settings"
                    icon={<BiCog color="white" />}
                    label="Settings"
                  />
                </Stack>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box
          flex="1"
          h="100%"
          p={{ base: '0', md: '0' }}
          marginStart={{ md: 'var(--sidebar-width)' }}
          position="relative"
          left={isOpen ? 'var(--sidebar-width)' : '0'}
          transition="left 0.2s"
        >
          <Box maxW="2560px" bg={mode('white', 'black')} minH="100vh">
            <Flex direction="column" overflow="auto" height="full">
              <Flex
                px={{ base: '5', md: '10' }}
                borderBottomWidth="1px"
                borderBottomColor={borderColor}
                w="full"
                py="4"
                justify="space-between"
                align="center"
              >
                <Flex align="center" minH="8">
                  <MobileMenuButton onClick={toggle} isOpen={isOpen} />
                  <NavBreadcrumb links={links} />
                </Flex>
              </Flex>
              <Box>{children}</Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default Layout;
