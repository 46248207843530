/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";
import useStore from "../../../logic/zustand";
import TYPES from "../../../config/types";

interface Feedback {
    message: string,
    success: boolean
}
interface IProps {
    feedback: Feedback
}

const Notification = (props: IProps) => {
    const { feedback } = props;
    const [show, setShow] = useState(true);

    const {setSelect} = useStore((store) => ({
        setSelect: store.setSelect
    }))


    useEffect(() => {
        const timeId = setTimeout(() => {
            setShow(false);
            setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, false);

        }, 20000)
        return () => {
            clearTimeout(timeId)
        }
    }, []);

    if (!show) {
        return null
    }

    return (
        <Alert h={{max: 'max-content'}} borderRadius="md" status={feedback?.success === true ? 'success' : 'error'} py={2}> <AlertIcon/>{feedback?.message}</Alert>
    )

};

export default Notification;