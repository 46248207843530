/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Switch } from '@chakra-ui/switch';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { IRules } from '../shared/interface';

interface IProps {
  label?: string;
  name: string;
  control?: any;
  register?: any;
  rules?: IRules;
  errors?: any;
}

const FormSwitch: FC<IProps> = ({
  name,
  label,
  control,
  rules,
  register,
  errors,
}) => (
    <FormControl isInvalid={errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        {...register(name, rules)}
        render={({field: {value, onChange}}) => (
            <Switch isChecked={value} onChange={onChange} />
        )}
      />
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );

export default FormSwitch;
