/* eslint-disable @typescript-eslint/no-explicit-any */
import create from "zustand";
import { devtools } from "zustand/middleware";
import initModalModel from "./initModalModel";
import initSetterModel from "./initSetterModal";

import ModalComponents from "../../lib/modal"
import TYPES from "../../config/types";


const useStore = create<any>(
    devtools((set, get) => ({
        ...initSetterModel(set, get, 'select', []),
        ...initSetterModel(set, get, 'waiting', []),
        ...initSetterModel(set, get, 'userInputs', []),
        ...initModalModel(set, get, ModalComponents, TYPES.MODALS),
    }))
);

export default useStore;