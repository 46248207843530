import { useToast } from "@chakra-ui/react";
import TYPES from "../../config/types";
import { capitalizeString } from "../../helpers/utility";
import { models } from "../queries";
import useMutate from "../queries/useMutate";
import useStore from "../zustand";

const useJoinWaitlistFlow = () => {

    const {setWaiting, clearWaiting, closeModal} = useStore((store) => ({
        setWaiting: store.setWaiting,
        clearWaiting: store.clearWaiting,
        closeModal: store['modal/close']
    }));

    const toast = useToast();

    const joinWaitlist = useMutate({
        triggerId: models.services.actions.joinWaitlist,
        queryKey: `${models.services.name  }-join-waitlist`
    })

    const joinWaitlistFlow = async (payload) => {
        try {
            setWaiting(TYPES.WAITING.PROCESSING.JOIN_WAITLIST, true);
            await joinWaitlist(payload);
            closeModal();
            toast({
                title: 'Added to waitlist.',
                description: `You just claimed your spot on the  ${payload.microservice} waitlist.`,
                status: 'success',
                duration: 10000,
                isClosable: true,
                position: 'top'
              })

        } catch (error) {
            closeModal();
            toast({
                title: 'Some error occurred.',
                description: `Could not add ${payload.microservice} to waitlist.`,
                status: 'error',
                duration: 10000,
                isClosable: true,
                position: 'top'
              })
        } finally {
            clearWaiting(TYPES.WAITING.PROCESSING.JOIN_WAITLIST)
        }
    };

    return joinWaitlistFlow;

};

export default useJoinWaitlistFlow;