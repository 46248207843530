import {
  Button,
  ModalCloseButton,
  useBreakpointValue,
  useColorModeValue,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import { Box, Center, Heading, Text } from '@chakra-ui/layout';
import React, { FC, ReactElement } from 'react';
import useJoinWaitlistFlow from '../../../logic/flows/useJoinWaitlist';
import TYPES from '../../../config/types';
import useStore from '../../../logic/zustand';


const BuildableJModal: FC = (): ReactElement => {

  const joinWaitlist = useJoinWaitlistFlow();

  const {
    microservice,
    isOpenModal,
    closeModal,
  } = useStore((store) => ({
    microservice: store.select[TYPES.SELECT.MICROSERVICE],
    isOpenModal: store.modal.isOpen,
    closeModal: store['modal/close'],
  }));

  const size = useBreakpointValue({md: 'lg'});
  const isCentered = useBreakpointValue({md: true});
  const bg = useColorModeValue('white', 'black');

  return (
    <Modal size={size} onClose={closeModal} isOpen={isOpenModal} isCentered={isCentered}>
    <ModalOverlay />
    <ModalContent rounded={[0, 'md']}>
      <ModalCloseButton />
      <ModalBody maxH="max-content" rounded="md" bg={bg}  py="5">
    <Box p={5} alignItems="center">
      <Box w="100%">
        <Center>
          <Heading size="sm">Request access to {microservice?.spec?.title}</Heading>
        </Center>
      </Box>
      <Text mt={3} size="md" textAlign="center">
        {microservice?.spec?.subTitle}
      </Text>
      <Button
      onClick={() => joinWaitlist({microservice: microservice?.spec?.title})}
        mt={5}
        minW="100%"
        colorScheme="blue"
      >
        Join the waitlist
      </Button>
    </Box>
    </ModalBody>
    </ModalContent>
    </Modal>
  );
};

export default BuildableJModal;
