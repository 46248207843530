import { Button } from "@chakra-ui/react";
import { FC } from "react";

interface IProps {
    isLoading?: boolean,
    isFullWidth?: boolean,
    isDisabled?: boolean,
    colorScheme?: string,
    type?: 'reset' | 'button' | 'submit' | undefined,
    label?: string,
}

const FormButton: FC<IProps> = ({label, isLoading, isFullWidth, isDisabled, colorScheme, type}) => (
        <Button isLoading={isLoading} isDisabled={isDisabled} colorScheme={colorScheme} type={type} isFullWidth={isFullWidth}>{label}</Button>
    );

export default FormButton;