import TYPES from "../../config/types";
import { useCreate } from "../queries";
import useStore from "../zustand";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Credentials {
    [k: string]: any;
}

interface IProps {
    triggerId: string
}

const useCreateTestConnectionFlow = (props: IProps) => {
    const { triggerId } = props;
    const {setWaiting, clearWaiting, setSelect} = useStore((store) => ({
        setWaiting: store.setWaiting,
        clearWaiting: store.clearWaiting,
        setSelect: store.setSelect
    }));
    const createTestConnection = useCreate({
        triggerId,
        queryKey: 'microservice-test-connection'
    }); 
    const createTestConnectionFlow = async (credentials: Credentials) => {
        try {
            setWaiting(TYPES.WAITING.PROCESSING.CREATE_TEST_CONNECTION, true);
            const response = await createTestConnection(credentials);
            setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, response);

        } catch ({response}) {
            setSelect(TYPES.SELECT.CONNECTION_FEEDBACK, {
                message: JSON.parse(response?.data?.message)?.message || 'Some error occurred!'
            });
        } finally {
            clearWaiting(TYPES.WAITING.PROCESSING.CREATE_TEST_CONNECTION);
        }
    };

    return createTestConnectionFlow;
};

export default useCreateTestConnectionFlow;